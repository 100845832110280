.clients-section {
  text-align: center;
  padding: 55px 20px;
  max-width: 1800px;
  margin: 0 auto;
}

.clients-title {
  font-size: 30px;
  margin-bottom: 20px;
  color: var(--black-1);
  font-family: "Montserrat", serif;
  font-weight: 700;
}

.clients-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  padding: 8px 0;
}

.clients-track {
  display: flex;
  gap: 50px;
  width: max-content;
  animation: scrollClients 17s linear infinite;
}

.client-card {
  background-color: var(--gray-5);
  border-radius: 12px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 190px;
  height: 130px;
  transition: transform 0.1s ease;
}

.client-card img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.client-card:hover {
  transform: scale(1.1);
}

@keyframes scrollClients {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 768px) {
  .clients-title {
    font-size: 22px;
  }

  .client-card {
    width: 150px;
    height: 100px;
  }

  .clients-track {
    gap: 6px;
  }
    
}

@media screen and (max-width: 1200px) {
    .clients-title {
        font-size: 24px;
      }
      .clients-track {
        gap: 20px;
      }
  }
