.footer-wrapper {
  padding: 25px 30px;
  background-color: var(--gray-3);
  z-index: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center; 
}

.column-wrapper {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between; 
  align-items: flex-start; 
  max-width: 1500px; 
  width: 100%;
  margin: 0 auto; 
  color: var(--white-1);
}

.column-1,
.column-2,
.column-3 {
  flex: 1 1 33.33%; 
  padding: 15px; 
  min-width: 200px;
}

.column-2,
.column-3 {
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  text-align: center; 
}

/*Column 1 styles*/
.column-1 {
  font-family: "Hind", sans-serif;
  font-size: 16px;
}

.column-1 p {
  margin-top: 10px;
}

/*Column 2 styles*/
.column-2 a {
  font-family: "Oswald";
  font-weight: 400;
  font-style: normal;
  color: var(--white-1);
  text-decoration: none;
  font-size: 18px;
}

.column-2 a:first-of-type{
    margin-left: -9px;
}

.column-2 a:hover {
  text-decoration: underline;
}

/*Column 3 styles*/
.column-3 {
  font-family: "Hind", sans-serif;
  font-size: 18px;
}

.icons-container {
  display: flex;
}

.icons-fill-f {
  width: 45px;
  height: 45px;
  background-color: var(--red-1);
  transition: transform 0.3s ease;
  border-radius: 50%;
  padding: 8px 9px;
  margin-bottom: 2px;
  margin: 0px 7px;
}

.icons-fill-f svg {
  width: 27px;
  height: 25px;
}

.icons-fill-f:hover {
  transform: scale(1.2);
}

@media (max-width: 439px) {
    .footer-wrapper {
      padding-left: 10px;
    }
    
    .column-2, .column-3 {
      justify-content: start;
      align-items: start; 
      text-align: start; 
    }
  
    .column-2 a:first-of-type{
      margin-left: 0px;
  }
  }