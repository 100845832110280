.navbar-body {
  background-color: var(--white-1);
  gap: 1px;
}

a.nav-link {
  font-family: "Oswald";
  font-weight: 400;
  font-style: normal;
  color: var(--gray-2);
}

a.nav-link:hover {
  text-decoration: underline;
}

.icons-fill {
  width: 40px;
  height: 40px;
  background-color: var(--red-1);
  transition: transform 0.3s ease;
  border-radius: 50%;
  padding: 5.5px 8px;
  margin-bottom: 2px;
  margin: 0px 7px;
}

.icons-fill:hover {
  transform: scale(1.2);
}

.navbar-toggler {
  border-color: var(--gray-2);
  border-width: 2px;
  border-radius: 5px;
}

.icons-container {
  display: flex;
}

.icons:hover {
  transform: scale(1.2);
}

.options-container {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .options-container {
    justify-content: flex-start;
    padding: 8px;
    border-color: var(--gray-1);
    border-top: 2px solid;
  }

  .icons-container {
    display: inline-flex;
  }

  .icons-fill {
    margin: 0 6px;
  }
}
