.background-wrapper-second-section {
  height: max-content;
  padding: 80px 15px 40px;
  background-color: var(--white-1);
  display: flex;
  flex-direction: row;
  max-width: 1800px;
  margin: 0 auto;
  overflow: hidden;
  align-items: center;
}

.solutions-text{
  max-width: 1800px;
  padding-right: 60px;
}

.solutions-img {
  height: 100%;
  width: 60%;
  max-width: 600px;
  max-height: 400px;
  margin: 0 auto;
}

.solutions-paragraph {
  font-family: "Hind", serif;
  font-style: normal;
  color: var(--gray-4);
  font-size: 16px;
}

.solutions-title {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  color: var(--gray-4);
}

.solutions-subtitle {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  color: var(--red-2);
}

.solutions-list {
  font-family: "Hind", serif;
  font-style: normal;
  color: var(--gray-2);
  padding-left: 20px;
  font-size: 18px;
}

.list-title {
  font-family: "Hind", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: var(--gray-2);
  padding-left: 0;
}

ul {
  list-style-type: "<> ";
}

li::marker {
  font-family: "Oswald";
  font-weight: 400;
}



@media (max-width: 768px) {
  .background-wrapper-second-section  {
    flex-direction: column;
    padding: 40px 25px;
  }

  .solutions-img {
    width: 80%;
    padding-bottom: 12px;
  }

  .solutions-title {
    font-size: 20px;
  }

  .solutions-subtitle {
    font-size: 32px;
  }

  .solutions-paragraph {
    font-size: 14px;
  }

  .list-title {
    font-size: 20px;
  }

  .solutions-list {
    font-size: 16px;
  }
}


/*Tablet font sizes*/
@media (769px <= width <= 1300px) {

  .background-wrapper-second-section  {
    padding: 40px 0;
  }
  .solutions-title {
    font-size: 18px;
  }

  .solutions-subtitle {
    font-size: 32px;
  }

  .solutions-paragraph {
    font-size: 15px;
  }

  .list-title {
    font-size: 22px;
  }

  .solutions-list{
    font-size: 17px;
  }
}