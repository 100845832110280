:root {
    /* Whites */
    --white-1: #FFFFFF;    
  
    /* Grays */
    --gray-1: #737373;
    --gray-2:#262626;
    --gray-3: #3E3E3F;
    --gray-4:#4F4F4F;
    --gray-5:#D9D9D9;

    /*Reds*/
    --red-1:#A60505; /*Rojo logo Arosoft*/
    --red-2:#990000;

    /*Blacks*/
    --black-1:#000000;
    
  }
  