.hero-background {
  background-image: url(https://arosoft.hn/cdn-images/home_page/hero_section/Desktop_background_small-2.jpg);
  background-size: cover;
  display: grid;
  background-position: center;
  justify-content: space-between;
}

.hero-title {
  font-family: "Montserrat", serif;
  font-weight: 600;
  margin-top: 50px;
  color: var(--gray-2);
}

.hero-subtitle {
  font-family: "Montserrat", serif;
  font-weight: 800;
  color: var(--red-2);
  margin-top: -18px;
}

.hero-paragraph {
  font-family: "Hind", serif;
  font-weight: 500;
  font-style: normal;
  color: var(--gray-2);
  padding-left: 10px;
  padding-right: 10px;
}

.hero-button {
  border-radius: 8px;
  max-width: 170px;
  padding: 8px 25px 5px;
  background-color: transparent;
  font-family: "Hind", serif;
  font-weight: 400;
  font-style: normal;
  border: 1px solid var(--gray-4);
  color: var(--gray-4);
  -webkit-appearance: none;
  appearance: none;
  margin-left: 10px;
}

.hero-button:hover {
  background-color: var(--gray-4);
  color: var(--white-1);
}

@media (max-width: 768px) {
  .hero-background {
    background-image: url(https://arosoft.hn/cdn-images/home_page/hero_section/mobile-background.jpg);
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    padding: 16px 10px 30px;
  }

  .work-people {
    display: none;
  }

  .images-content {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 70px;
  }

  .hero-gif {
    justify-self: end;
    max-height: 115px;
    margin-top: -10px;
    padding-right: 10px;
    margin-right: -5px;
  }

  .work-person {
    justify-self: start;
    margin-top: -100px;
    max-height: 300px;
  }

  .hero-title {
    padding: 5px 10px;
    font-size: 18px;
  }

  .hero-subtitle {
    padding: 5px 10px;
    font-size: 38px;
  }

  .hero-paragraph {
    font-size: 14px;
  }

  .hero-button {
    font-size: 14px;
  }
}

@media (min-width: 525px) {
  .work-person {
    justify-self: center;
  }

  .hero-gif {
    padding-right: 60px;
    max-height: 150px;
  }
}

/*Tablet and Desktop properties*/
@media (min-width: 769px) {
  .hero-background {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-bottom: 80px;
    margin-top: -10px;
  }

  .images-content {
    display: grid;
    place-items: center;
    padding-top: 80px;
  }

  .work-person {
    display: none;
  }

  .information-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 80px;
    padding-left: 10px;
    max-width: 1000px !important;
  }

  .hero-title {
    font-size: 20px;
  }

  .hero-subtitle {
    padding-top: 10px;
    font-size: 40px;
  }

  .hero-paragraph {
    font-size: 16px;
    padding: 10px 0;
  }

  .hero-button {
    font-size: 16px;
    max-width: 400px;
    padding: 8px 50px 5px;
    margin-left: 0;
  }

  .hero-gif {
    max-height: 190px;
    padding-right: 0;
  }

  .work-people {
    max-width: 440px;
    margin-top: -30px;
    margin-left: 30px;
  }
}

@media (769px <= width <= 900px) {
  .work-people {
    margin-left: 14px;
  }

  .information-content {
    padding-right: 50px;
    padding-left: 25px;
  }
}

@media (min-width: 901px) {

  .work-people {
    margin-left: 0;
  }

  .images-content {
    margin-left: 40px;
  }
}

@media (min-width: 1700px) {
  .hero-background {
    background-image: url(https://arosoft.hn/cdn-images/home_page/hero_section/Desktop_background_large-2.jpg); /* Cambia al fondo más grande */
  }

  .images-content {
    /*since this size, be aligned to the right*/
    justify-self: end;
    margin-right: 110px;
  }
}
