.background-maintenance{
    background-image: url('https://arosoft.hn/cdn-images/maintenance/maintegif.gif'); 
    min-height: 800px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 0;
    background-position: center;
}

.maint-title{
    padding-top: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 38px;
    text-align: center;
    font-weight: 800;
    color: var(--red-2);
}

.maint-subtitle{
    font-family: "Hind", sans-serif;
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    color: var(--black-1);
}

@media (max-width: 768px) {

    .maint-title{
        padding-top: 0px;
        font-size: 28px;
    }

    .maint-subtitle{
        font-size: 18px;
    }

    .background-maintenance{
        padding: 45px 15px 0;
        min-height: 500px;
    }
}