.contact-section-container {
  padding: 10px 0 70px;
  max-width: 1800px;
  margin: 0 auto;
  height: fit-content;
}

.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--gray-5);
  margin: 0 70px;
  padding: 25px 70px;
  border-radius: 20px;
}

.contact-title {
  font-family: "Montserrat", sans-serif;
  font-size: 34px;
  text-align: center;
  font-weight: 600;
}

.contact-button {
  border-radius: 10px;
  border-width: 1px;
  background: linear-gradient(to right, var(--white-1) 50%, var(--black-1) 50%);
  background-size: 200% 100%;
  background-position: left;
  border-color: var(--black-1);
  border-style: solid;
  -webkit-appearance: none;
  appearance: none;
  color: var(--black-1);
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 500;
  padding: 10px 20px;
  font-size: 20px;
  transition: background-position 0.4s ease-in-out, color 0.3s ease-in-out;
}

.contact-button:hover {
  background-position: right;
  color: var(--white-1);
}


@media screen and (max-width: 768px) {
  .contact-title {
    font-size: 24px;
  }
  .contact-section-container {
    padding: 0 0 50px;
  }

  .contact-section{
    margin: 0 30px;
    padding: 20px 25px;
  }

  .contact-button{
    font-size: 16px;
  }

  .arrow{
    font-size: 20px;
    padding: 1px 0px;
  }
}

@media screen and (max-width: 1200px) {
  .contact-title {
    font-size: 24px;
  }

  .contact-button{
    font-size: 16px;
  }

}