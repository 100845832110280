.contact-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 40px;
}

.contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--gray-5);
  border-radius: 20px;
  height: fit-content;
  padding: 40px 50px;
  width: 100%;
}

.contact-img {
  width: max-content;
  height: 300px;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
}

.mwcontact-title {
  font-size: 40px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: var(--red-2);
}

.mwcontact-paragraph {
  font-size: 20px;
  font-weight: 400;
  font-family: "Hind", sans-serif;
  color: var(--black-1);
  text-align: center;
  margin-bottom: 20px;
}

.contact-us-button {
  background: var(--red-2);
  color: var(--white-1);
  font-size: 20px;
  font-weight: 500;
  font-family: "Hind", sans-serif;
  padding: 10px 20px;
  margin-bottom: 14px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-style: none;
}

.contact-us-button:hover {
  background: var(--white-1);
  color: var(--red-2);
}

@media (max-width: 768px) {
  .contact-wrapper {
    padding: 30px 35px;
  }

  .contact-img {
    width: 90%;
    height: 80%;
  }

  .mwcontact-title {
    font-size: 28px;
    text-align: center;
  }

  .mwcontact-paragraph {
    font-size: 16px;
  }

  .contact-us-button {
    font-size: 16px;
  }
}
