.separator-line {
  display: flex;
  padding-bottom: 12px;
  max-width: 1800px; /* Límite máximo del contenedor */
}

.first-line {
  border-top: 4px solid var(--red-2);
  width: 5%;
  margin-right: 3px;
  max-width: 10%;
}

.second-line {
  border-top: 4px solid var(--gray-1);
  width: 20%;
  padding-right: 10px;
  max-width: 30%;
}

@media (max-width: 768px) {
  .first-line {
    width: 20%;
    max-width: 20%;
  }

  .second-line {
    width: 85%;
    max-width: 85%;
  }
}
