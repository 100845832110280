.background-services {
  background-image: url(https://arosoft.hn/cdn-images/home_page/services_section/contacto.jpg);
  background-size: cover;
  background-position: center;
  height: fit-content;
}

.services-wrapper {
  padding: 50px 15px;
  justify-content: center;
}

.services-cards-section {
  display: flex;
  justify-content: center;
}

.services-title {
  font-size: 22px;
  font-family: "Montserrat", serif;
  font-weight: 600;
  margin-top: 50px;
  color: var(--gray-2);
  text-align: center;
}

.services-subtitle {
  font-size: 30px;
  font-family: "Montserrat", serif;
  font-weight: 800;
  color: var(--red-2);
  margin-top: -18px;
  text-align: center;
  padding-top: 20px;
}

@media screen and (max-width: 768px) {
  .services-cards-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .services-wrapper {
    padding: 5px 15px 30px;
  }

  .services-subtitle {
    font-size: 25px;
  }

  .services-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .services-subtitle {
    font-size: 24px;
  }

  .services-title {
    font-size: 18px;
  }
}
