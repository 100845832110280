.background-wrapper-third-section {
  height: max-content;
  padding: 50px 80px;
  background-color: var(--white-1);
  display: flex;
  flex-direction: row;
  max-width: 1800px;
  margin: 0 auto;
  overflow: hidden;
  align-items: center;
}

.businessman-img{
    width: 35%;
    max-width: 600px;
    margin: 0 auto;
}

.text-section{
    max-width: 1800px;
}

.title{
    font-family: "Montserrat", serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    color: var(--gray-4);
}

.subtitle{
    font-family: "Montserrat", serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    color: var(--red-2);
}

.paragraph{
    font-family: "Hind", serif;
    font-style: normal;
    color: var(--gray-4);
    font-size: 16px;
}

@media (max-width: 768px) {
    .background-wrapper-third-section {
      flex-direction: column;
      padding: 0 25px 45px;
    }
  
    .businessman-img {
      width: 55%;                                                                        
      padding-bottom: 12px;
    }

    .text-section{
        margin-bottom: 10px;
    }

    .title{
        font-size: 20px;
    }

    .subtitle{
        font-size: 30px;
    }

    .paragraph{
        font-size: 14px;
    }
  }

  @media (769px <= width <= 1300px) {
    .title{
        font-size: 18px;
    }

    .subtitle{
        font-size: 26px;
    }

    .paragraph{
        font-size: 15px;
    }
  }