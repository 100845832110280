.services-wrapper{
  color: var(--white-1);
}

.services-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding: 32px 22px 15px;
  margin: 20px;
  border-radius: 10px;
  background-color: var(--red-2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 400px;
  max-width: 400px;
}

.card-button {
  position: absolute;
  bottom: 15px; 
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--gray-2);
  border-style: none;
  margin-left: 10px;
  width: 36px;
  height: 35px;
  transition: transform 0.3s ease;
}

.more-button {
  border-radius:10px;
  border-width: 1px;
  background-color: var(--white-1);
  border-color: var(--white-1);
  position: absolute;
  border-style:solid;
  bottom: 15px; 
  -webkit-appearance: none;
  appearance: none;
  color: var(--black-1);
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 500;
  padding: 8px 17px;
  font-size: 15px;
}

.more-button:hover {
  background-color: var(--black-1);
  border-color: var(--black-1);
  color: var(--white-1);
}

.back-description{
  margin-top: -32px;
}

.arrow-button-forward{
  padding-left: 10px;
}
.arrow-button-back{
  padding-left: 15px;
}

.card-button:hover {
 transform: scale(1.2);
}

.card-title{
  font-size: 24px;
  font-family: "Montserrat", serif;
  font-style: normal;
  padding-bottom: 5px;
}

.card-description{
  font-size: 16px;
  font-family: "Hind", serif;
  font-style: normal;
}


@media screen and (max-width: 768px) {

  .card-title {
    font-size: 18px;
    font-family: "Montserrat", serif;
    font-weight: 600;
  }

  .card-description {
    font-size: 14px;
    font-family: "Hind", sans-serif;
    font-weight: 400;
    padding-top: 5px;
  }

  .more-button {
    font-size: 15px;
    padding: 7px 16px;
  }

}

@media (769px <= width <= 1300px ) {
  
  .card-title {
    font-size: 22px;
    font-family: "Hind", serif;
    font-style: normal;
  }

  .card-description {
    font-size: 14px;
  }

  .services-card {
    width: fit-content;
    margin: 0px;
    height: 375px;
    padding: 38px 18px 15px
  }
}